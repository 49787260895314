import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useLocation, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import formStyles from "../formStyles";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import CopyRight from "../components/copyright";
import MainLayout from "../layouts/mainLayout";
import { useTranslation } from "react-i18next";

export default function VerifyPage(props) {
  const { token } = useParams();
  const { t } = useTranslation();

  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState({});
  const location = useLocation();
  const searchParams = location.search;

  const getApiData = async () => {
    fetch("/account/verify/" + token)
      .then((response) => response.json())
      .then((data) => {
        setHttpResponse(data);
      })
      .catch((error) => {
        setHttpResponse({ error_code: "500" });
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getApiData();
  }, [token]);

  return (
    <MainLayout>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" style={{ marginBottom: "15px" }}>
        {t("account_verification")}
      </Typography>

      {(httpResponse.error_code === "40000" && (
        <Alert variant="outlined" severity="error">
          {t("errors.invalid_link")}
        </Alert>
      )) ||
        (httpResponse.error_code === "40101" && (
          <Alert variant="outlined" severity="error">
            {t("errors.invalid_token")}
          </Alert>
        )) ||
        (httpResponse.error_code === "40007" && (
          <Alert variant="outlined" severity="error">
            {t("errors.token_expired")}
          </Alert>
        )) ||
        (httpResponse.error_code === "500" && (
          <Alert variant="outlined" severity="error">
            {t("errors.something_went_wrong")}
          </Alert>
        )) ||
        (httpResponse.status === "success" && (
          <Alert variant="outlined" severity="success">
            {t("success.verify_success")}
          </Alert>
        )) ||
        (httpResponse && (
          //Default
          <Alert variant="outlined" severity="error">
            {t("errors.something_went_wrong_contact_admin")}
          </Alert>
        ))}
      <Grid container style={{ marginTop: "15px" }}>
        <Grid item xs>
          <Link component={ReactLink} to={"/" + searchParams} variant="body2">
            {t("all_ready_registered")}
          </Link>
        </Grid>
        <Grid item>
          <Link
            component={ReactLink}
            to={"/register" + searchParams}
            variant="body2"
          >
            {t("not_signed_up")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
