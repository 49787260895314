export const transformPermittedQueryToObject = (query) => {
  const permittedQuery = ["kpId", "token", "timeSet", "mode"];
  const params = new URLSearchParams(query.split("?").pop());
  const result = {};

  permittedQuery.forEach((key) => {
    if (params.has(key)) {
      const value = params.get(key);
      result[key] = value === "true" ? true : value === "false" ? false : value;
    }
  });

  return result;
};
