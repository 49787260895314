export const transformPermittedQueryToObject = (query) => {
    const permittedQuery = ['s', 't', 'с', 'm'];

    const queryObject = query
        .replace('?', '')
        .split('&')
        .reduce((acc, item) => {
            const [key, value] = item.split('=');
            if (permittedQuery.includes(key)) {
            acc[key] = value;
            }
            return acc;
        }, {});

    return queryObject;

};