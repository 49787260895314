import settings from "../config/settings";

export const SendUserBack = (location) => {
  const searchParams = location.search;
  const urlParams = new URLSearchParams(searchParams);
  const backTo = urlParams.get("back_to");

  if (backTo) {
    const redirectUrl = searchParams.replace(/^(\?back_to=)/, "");
    window.location = redirectUrl;
    return;
  }

  if (urlParams.has("token")) {
    window.location =
      settings.MANAGER_URL +
      "/manager/organizations/register/locks?" +
      urlParams.toString();
    return;
  }

  if (settings.MANAGER_URL) {
    window.location = settings.MANAGER_URL;
    return;
  }

  let url = document.referrer;
  if (
    url.includes("telkey.com") ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    window.location = url;
  }
};
