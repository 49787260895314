import settings from "../config/settings";

export const SendUserBack = () => {
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const backTo = urlParams.get("back_to");
  const backToFromString = currentUrl.split("back_to=")[1];
  const paramsFromUrl = currentUrl?.split("?")[1];

  if (paramsFromUrl?.length > 1 && paramsFromUrl.includes("s=")) {
    const params = new URLSearchParams(paramsFromUrl);
    window.location =
      settings.MANAGER_URL +
      "/manager/organizations/register/locks?" +
      params.toString();
    return;
  }
  if (backTo?.length || backToFromString?.length) {
    window.location = backTo || backToFromString;
    return;
  }
  if (settings.MANAGER_URL) {
    window.location = settings.MANAGER_URL;
    return;
  }

  let url = document.referrer;
  if (
    url.includes("telkey.com") ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    window.location = url;
  }
};
